<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card radius-lr10">
          <div class="card-header card-default">
            <div class="row">
              <div class="col-md-7">
                <p id="idExsampel" class="text-muted margin-b-5" >
                  Entri Kartu Rencana Studi Mahasiswa
                </p>
              </div>
              <div class="col-md-5">
                <select class="selectTahun form-control form-control-rounded50" data-placeholder="Piliih Tahun Akademik" data-allow-clear="1"
                  v-model="dataSMT.smt_id">
                  <option v-for="smt in tahunAkademik" v-bind:value="smt.smt_id" :key="smt.smt_id">
                    {{ smt.smt_nama }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body padding-t-0 ">
            <div class="widget white-bg" v-show="!isLoading">
              <div class="row">
                <div class="col-md-4 col-xs-6 b-r"> <strong>{{ profil.nama_mahasiswa }}</strong>
                  <br>
                  <p class="text-muted">{{ userData.username }}</p>
                </div>
                <div class="col-md-4 col-xs-6 b-r"> <strong>Program Studi</strong>
                  <br>
                  <p class="text-muted">{{ profil.jenj_didik + ' ' + profil.nama_prodi }}</p>
                </div>
                <div class="col-md-2 col-xs-6 b-r"> <strong>Tahun Akademik</strong>
                  <br>
                  <p class="text-muted">{{ dataSMT.smt_nm }}</p>
                </div>
                <div class="col-md-2 col-xs-6"> <strong>Jumlah SKS</strong>
                  <br>
                  <p class="text-muted">{{ jumlahSKS }}</p>
                </div>
              </div>
            </div>
            <template v-if="!isLoading">
              <!-- Component status KRS -->
              <krs-status v-show="isTableKrs" :validasi="statusValidasi">
                <template v-slot:header>
                  <div v-show="isTableKrs" class="float-right mt-10">
                    <button type="button" v-on:click="downloadKRS"  class="btn btn-warning btn-rounded btn-sm btn-icon"><i class="fa fa-cloud-download"></i> Download &nbsp;</button> &nbsp;
                    <button type="button" v-on:click="printKRS"  class="btn btn-primary btn-rounded btn-sm btn-icon"><i class="fa fa-print"></i> Cetak &nbsp;</button> &nbsp; &nbsp;
                  </div>
                </template>
              </krs-status>
              <table class="table" v-show="isTableKrs">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>KODE</th>
                    <th>NAMA MATAKULIAH</th>
                    <th>S K S</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(krs, num) in resourceKRS" :key="krs.id_nil">
                    <td>{{ num + 1 }}</td>
                    <td>{{ krs.kd_mak }}</td>
                    <td>{{ krs.nm_mak }}</td>
                    <td>{{ krs.mk_sks }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import printJS from 'print-js'
import KrsStatus from '@/components/krs/KrsStatus.vue'
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { getMhs, getSMT } from '@/services/mahasiswa.service'
import { getKRS, cetKRS } from '@/services/krs.service'
import { ref } from 'vue'
export default {
  name: 'RiwayatKhs',
  components: {
    Loading,
    KrsStatus
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userData: users,
      dataKRS: [],
      tahunAkademik: [],
      dataSMT: {},
      profil: {},
      statusKRS: {},
      params: {
        smt_id: ''
      },
      isLoadSMT: ref(false),
      isLoadMHS: ref(false),
      isLoadKRS: ref(false),
      isLoadCET: ref(false),
      fullPage: true,
      onClikTahun: false,
      errorData: {}
    }
  },
  computed: {
    isLoading: function () {
      const load = !!((this.isLoadMHS || this.isLoadKRS || this.isLoadSMT || this.isLoadCET))
      return load
    },
    isTableKrs: function () {
      return (this.statusKRS?.status_acc === '1')
    },
    statusValidasi: function () {
      return this.statusKRS
    },
    resourceKRS: function () {
      return this.dataKRS
    },
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    }
  },
  created () {
    this.getTahunAkademik()
    this.dataMahasiswa()
  },
  mounted () {
    var vm = this
    $('.selectTahun').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: $(this).data('placeholder'),
      allowClear: Boolean($(this).data('allow-clear'))
    }).on('select2:select', function (e) {
      vm.params.smt_id = e.params.data.id
      vm.onClikTahun = true
      vm.getData()
    }).trigger('change')
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '293px',
      alwaysVisible: false
    })
  },
  methods: {
    async getTahunAkademik () {
      this.isLoadSMT = true
      getSMT({}).then(response => {
        if (response.error_code === 200) {
          this.tahunAkademik = response.records
          this.isLoadSMT = false
        }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoadSMT = false
      })
    },
    async dataMahasiswa () {
      this.isLoadMHS = true
      getMhs({ nipd: this.userData.username })
        .then((response) => {
          this.profil = response.records
          this.isLoadMHS = false
          this.getData()
        })
        .catch((error) => {
          this.errorData = (error.response) ? {} : {}
          this.isLoadMHS = false
        })
    },
    async getData () {
      this.isLoadKRS = true
      const params = {
        id_reg_pd: this.profil.id_mahasiswa,
        th_smtmasuk: this.profil.id_mulai_masuk_smt,
        id_smt: this.params.smt_id
      }
      getKRS(params).then(response => {
        if (response.error_code === 200) {
          this.dataKRS = response.records
          this.dataSMT = response.tahun_akademik
          this.statusKRS = response.validasi
        } else {
          this.dataKRS = [{}]
          this.dataSMT = response.tahun_akademik
          this.statusKRS = { status_acc: '0', tanggal: '', jam: '' }
        }
        this.isLoadKRS = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoadKRS = false
      })
    },
    async printKRS () {
      this.isLoadCET = true
      cetKRS({ reg: this.profil.id_mahasiswa, smt: this.params.smt_id }).then((response) => {
        printJS(window.URL.createObjectURL(new Blob([response])))
        this.isLoadCET = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoadCET = false
      })
    },
    async downloadKRS () {
      this.isLoadCET = true
      cetKRS({ reg: this.profil.id_mahasiswa, smt: this.params.smt_id }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'KRS ' + this.profil.username + '.pdf')
        document.body.appendChild(fileLink)

        fileLink.click()
        this.isLoadCET = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoadCET = false
      })
    }
  }
}
</script>
